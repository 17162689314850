import { ActivityTypeID } from 'constants/enums'

type TConvertToHexCodeColorFn = (color?: string) => `#${string}` | ''
type TConvertActivityNameFn = (
  activityTypeId?: number,
) => '인사담당자' | '담당자' | ''

/**
 * @desc api 에서 받아온 색상 코드를 css에서 사용할 수 있는 rgba() 형태로 변환합니다.
 * @param color 0xAARRGGBB (알파값이 앞에 있는 형태)
 * @returns rgba()
 */
export const convertToHexCodeColor: TConvertToHexCodeColorFn = (color) => {
  if (!color) return ''
  const alpha = color.slice(2, 4)
  const red = color.slice(4, 6)
  const green = color.slice(6, 8)
  const blue = color.slice(8, 10)
  return `#${red}${green}${blue}${alpha}`
}

/**
 * @desc activityTypeId 따라 '인사담당자' 또는 '담당자' 텍스트를 추가합니다
 * @param activityTypeId 채팅방 타입
 * @returns
 */
export const convertActivityName: TConvertActivityNameFn = (activityTypeId) => {
  if (
    activityTypeId === ActivityTypeID.INTERN ||
    activityTypeId === ActivityTypeID.RECRUIT
  ) {
    return '인사담당자'
  } else if (
    activityTypeId === ActivityTypeID.ACTIVITY ||
    activityTypeId === ActivityTypeID.CONTEST
  ) {
    return '담당자'
  } else {
    return ''
  }
}
