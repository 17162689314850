import { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useRouter } from 'next/router'
import queryString from 'query-string'
import {
  CHAT_OPEN_TYPE,
  CHAT_OPEN_TYPE_QUERY_KEY,
} from '@app.feature/chatting/chattingContext/type/chattingQueryParams'
import AlertDialog, { AlertDialogProps } from 'components/AlertDialog'

const useStyles = makeStyles(() => {
  return {
    titleRoot: {
      fontSize: 18,
      fontWeight: 500,
      textAlign: 'center',
    },
  }
})

type LoginAlertProps = Partial<AlertDialogProps> &
  Pick<AlertDialogProps, 'onRequestClose' | 'DialogProps'> & {
    chatRoomId?: string
  }

const LoginAlert: FC<LoginAlertProps> = (props) => {
  const router = useRouter()
  const classes = useStyles()

  const goLogin = () => {
    const next = props.chatRoomId
      ? `${window.location.href}?${CHAT_OPEN_TYPE_QUERY_KEY}=${CHAT_OPEN_TYPE.DEFAULT}&chatRoomId=${props.chatRoomId}`
      : window.location.href

    router.push(`/login?${queryString.stringify({ next })}`)
  }

  return (
    <AlertDialog
      title={
        <div className={classes.titleRoot}>
          <div>로그인 후 이용가능한 서비스입니다.</div>
          <div>로그인 하시겠습니까?</div>
        </div>
      }
      onConfirm={goLogin}
      confirmLabel="확인"
      {...props}
    />
  )
}

export default LoginAlert
