import { FC } from 'react'
import { getHours, getMinutes } from 'date-fns'
import gql from 'graphql-tag'
import styled, { css } from 'styled-components'
import { MessageItem_ChatMessageFragment, UserType } from 'generated/graphql'
import MessageItemProfile from './components/MessageItemProfile'

gql`
  fragment MessageItem_chatMessage on ChatMessage {
    id
    content
    createdAt
    senderNickname
    viewerIsSender
    sender {
      chatUserType
      chatMentorAuthenticationStatus
      chatMentorOrganizationName
      chatMentorDutyName
      chatOrganization {
        name
      }
      profileImage {
        bgColor {
          id
          code
        }
        image {
          id
          url
        }
      }
    }
    chatRoom {
      activityTypeId
      isManagerRecentChatMessageSentInPopularChatRoom
      isMentorRecentChatMessageSentInPopularChatRoom
    }
  }
`

type TStyledWrapperProps = {
  viewerIsSender: boolean
}

interface IFMessageItemProps {
  message: MessageItem_ChatMessageFragment
}

const MessageItem: FC<IFMessageItemProps> = ({ message }) => {
  const messageCreatedAt = `${getHours(message.createdAt)} : ${getMinutes(
    message.createdAt,
  )
    .toString()
    .padStart(2, '0')}`

  const organizationManager =
    message.sender?.chatUserType === UserType.MANAGER_UNVERIFIED
      ? `${message.sender?.chatOrganization?.name}`
      : undefined

  const mentorUser =
    message.sender?.chatMentorAuthenticationStatus === 'APPROVED'
      ? `${message.sender?.chatMentorOrganizationName} · ${message.sender?.chatMentorDutyName}`
      : undefined

  return (
    <StyledWrapper viewerIsSender={message.viewerIsSender}>
      {!message.viewerIsSender && (
        <MessageItemProfile
          nickname={message.senderNickname}
          organizationManager={organizationManager}
          mentorUser={mentorUser}
          profileImageBackground={message.sender?.profileImage.bgColor.code}
          profileImageSrc={message.sender?.profileImage.image.url}
          activityType={message.chatRoom?.activityTypeId}
        />
      )}
      <div className="item-container">
        <div className="message-content">{message.content}</div>
        <div className="created-at">{messageCreatedAt}</div>
      </div>
    </StyledWrapper>
  )
}

export default MessageItem

const StyledWrapper = styled.div<TStyledWrapperProps>`
  ${({ theme, viewerIsSender }) => css`
    display: flex;
    flex-direction: column;
    gap: 5px;

    .item-container {
      display: flex;
      align-items: flex-end;
      padding-left: 30px;

      .message-content {
        max-width: 225px;
        padding: 10px 12px;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: -0.56px;
        word-break: break-all;
        border-radius: 8px;
        background-color: #f3f4f8;
      }

      .created-at {
        font-size: 12px;
        line-height: 2;
        letter-spacing: -0.48px;
        color: ${theme.color.text_2};
        margin: 0 5px 0 12px;
      }

      ${viewerIsSender &&
      css`
        flex-direction: row-reverse;

        .message-content {
          background-color: #eaf8ff;
        }
      `}
    }
  `}
`
