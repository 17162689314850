import { FC } from 'react'
import gql from 'graphql-tag'
import Image from 'next/image'
import styled from 'styled-components'
import { useGqlActivityChatMessageQuery } from 'generated/graphql'
import MessageItem from './messageItem/MessageItem'

gql`
  query gqlActivityChatMessage($chatRoomId: ID!, $first: Int) {
    chatMessagesByChatRoomId(chatRoomId: $chatRoomId, first: $first) {
      nodes {
        ...MessageItem_chatMessage
      }
    }
  }
`

interface IFActivityChatMessageProps {
  chatRoomId: string
  className?: string
}

const ActivityChatMessages: FC<IFActivityChatMessageProps> = ({
  chatRoomId,
  className,
}) => {
  const { data } = useGqlActivityChatMessageQuery({
    variables: { chatRoomId, first: 5 },
  })

  if (data?.chatMessagesByChatRoomId.nodes?.length === 0) {
    return (
      <EmptyWrapper>
        <Image
          src="/static/images/chat/chat-empty.svg"
          alt="빈 채팅방"
          width={56}
          height={56}
        />
        채팅방이 조용해요.
        <br />
        가장 먼저 대화를 시작해보세요!
      </EmptyWrapper>
    )
  }

  return (
    <StyledWrapper className={className}>
      {data?.chatMessagesByChatRoomId.nodes?.map((chatMessage) => (
        <MessageItem key={chatMessage.id} message={chatMessage} />
      ))}
    </StyledWrapper>
  )
}

export default ActivityChatMessages

const StyledWrapper = styled.div`
  height: 188px;
  display: flex;
  flex-direction: column-reverse;
  gap: 15px;
  padding: 0 20px;
  overflow-y: auto;
`

const EmptyWrapper = styled.div`
  height: 204px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.56px;
  color: #999;
`
