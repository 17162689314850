import { FC } from 'react'
import Image from 'next/image'
import styled, { css } from 'styled-components'
import {
  convertActivityName,
  convertToHexCodeColor,
} from '@app.feature/activity/activity.detail/module/chatRoom'

type TStyledWrapperProps = {
  bgColor?: string
  bgSrc?: string
}

interface IFProps {
  nickname: string
  organizationManager?: string
  mentorUser?: string
  profileImageSrc?: string
  profileImageBackground?: string
  activityType?: number
}

const MessageItemProfile: FC<IFProps> = ({
  nickname,
  organizationManager,
  profileImageBackground,
  profileImageSrc,
  mentorUser,
  activityType,
}) => {
  const bgColor = convertToHexCodeColor(profileImageBackground)

  const activityTypeName = convertActivityName(activityType)

  return (
    <StyledWrapper bgColor={bgColor} bgSrc={profileImageSrc}>
      <i className="profile-image" />
      <div>
        <p className="nickname">{nickname}</p>
        {mentorUser && (
          <>
            <Image
              src="/static/images/chat/chat-active.svg"
              width={16}
              height={16}
              alt="현직자 대화중 뱃지"
            />
            <p className="profile-mentor">{mentorUser}</p>
          </>
        )}
        {organizationManager && (
          <>
            <Image
              src="/static/images/chat/chat-active-manager.svg"
              width={16}
              height={16}
              alt={`기업 담당자 뱃지`}
            />
            <p className="profile-manager">
              <span>{organizationManager}</span>
              &nbsp;{activityTypeName}
            </p>
          </>
        )}
      </div>
    </StyledWrapper>
  )
}

export default MessageItemProfile

const StyledWrapper = styled.div<TStyledWrapperProps>`
  ${({ theme, bgColor, bgSrc }) => css`
    height: 25px;
    display: flex;
    align-items: center;
    gap: 5px;

    & > i {
      width: 25px;
      height: 25px;
      display: block;
      background: ${bgColor ? bgColor : 'transparent'} url(${bgSrc}) no-repeat
        50%/50%;
      border-radius: 50%;
    }

    & > div {
      display: flex;
      align-items: center;
      gap: 4px;

      & > .nickname {
        max-width: 100px;
        height: 25px;
        font-size: 12px;
        font-weight: bold;
        line-height: 23px;
        letter-spacing: -0.48px;
        text-align: left;
        color: ${theme.color.text_1};
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      & > .profile-manager {
        height: 23px;
        border-radius: 5px;
        background-color: #f2fcf6;
        font-size: 11px;
        font-weight: bold;
        line-height: 23px;
        letter-spacing: -0.44px;
        text-align: center;
        color: #30b243;
        display: flex;
        align-items: center;
        padding: 0 5px;
        flex-shrink: 0;

        & > span {
          max-width: 110px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: block;
        }
      }

      & > .profile-mentor {
        max-width: 120px;
        height: 23px;
        border-radius: 5px;
        background-color: #f5fbff;
        font-size: 11px;
        font-weight: bold;
        line-height: 23px;
        letter-spacing: -0.44px;
        text-align: center;
        color: ${theme.color.primary_2};
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0 5px;
      }
    }
  `}
`
