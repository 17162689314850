import { PageType } from 'constants/enums'
import { ActivityTypeID } from 'constants/enums'

export const LIST_OFFSET_DEFAULT = 0
export const LIST_LIMIT_DEFAULT = 10
export const ACTIVITY_PAGE_TYPE = PageType.ACTIVITY

export const CHAT_ROOM_JOINABLE_ACTIVITY_TYPE = [
  ActivityTypeID.RECRUIT,
  ActivityTypeID.ACTIVITY,
  ActivityTypeID.CONTEST,
  ActivityTypeID.EDUCATION,
]
