import { FC, useCallback, useRef, useState } from 'react'
import { useUA } from 'context'
import gql from 'graphql-tag'
import styled, { css } from 'styled-components'
import { ArrowLeft, ArrowRight } from 'svgIcons'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  AdStatus,
  AdType,
  AdvertisementOrderField,
  OrderDirection,
  useGqlHotLineQuery,
} from 'generated/graphql'
import Skeleton from '@app.components/Skeleton'
import HotLineItem from './HotLineItem'

gql`
  query gqlHotLine(
    $filterBy: AdvertisementFilter!
    $orderBy: AdvertisementOrder
  ) {
    advertisementList(filterBy: $filterBy, orderBy: $orderBy) {
      advertisements {
        ...HotLineItem_advertisment
      }
      totalCount
    }
  }
`

interface IFHotLineProps {
  className?: string
}

const HotLine: FC<IFHotLineProps> = ({ className }) => {
  SwiperCore.use([Autoplay, Navigation, Pagination])
  const { isMobile } = useUA()
  const [totalSlides, setTotalSlides] = useState<number>(0)

  const handleSwiper = useCallback((swiper) => {
    setTotalSlides(swiper.slides.length)
  }, [])

  const prevButtonRef = useRef<HTMLButtonElement>(null)
  const nextButtonRef = useRef<HTMLButtonElement>(null)

  const { data, loading } = useGqlHotLineQuery({
    variables: {
      filterBy: {
        adType: AdType.HOTLINE_BANNER,
        status: AdStatus.OPEN,
      },
      orderBy: {
        field: AdvertisementOrderField.SEQ,
        direction: OrderDirection.ASC,
      },
    },
  })
  const ads = data?.advertisementList?.advertisements || []

  return !loading && ads?.length !== 0 ? (
    <StyledWrapper className={className}>
      {!isMobile && (
        <>
          <button className="swiper-button-left" ref={prevButtonRef}>
            <ArrowLeft className="arrow-icon" />
          </button>
          <button className="swiper-button-right" ref={nextButtonRef}>
            <ArrowRight className="arrow-icon" />
          </button>
        </>
      )}
      <Swiper
        onSwiper={handleSwiper}
        modules={[Pagination, Autoplay, Navigation]}
        navigation={{
          prevEl: prevButtonRef.current,
          nextEl: nextButtonRef.current,
        }}
        loop
        slidesPerView={1}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        pagination={{
          clickable: true,
          type: 'fraction',
          el: 'custom-pagination',
        }}
      >
        {ads.map((ad, index) => {
          return (
            <SwiperSlide key={`swiper-slide-${index}`}>
              <HotLineItem
                key={ad.id}
                advertisement={ad}
                loading={loading}
                totalSides={totalSlides}
                activeSlide={index + 1}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </StyledWrapper>
  ) : (
    <Skeleton width={720} height={120} className="loading" />
  )
}

export default HotLine

const StyledWrapper = styled.div`
  position: relative;
  max-width: 720px;
  max-height: 120px;

  .swiper-button-left,
  .swiper-button-right {
    position: absolute;
    z-index: 2;
    width: 32px;
    height: 32px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border: none;
    cursor: pointer;
  }

  .swiper-button-left {
    top: 40%;
    left: -15px;
  }
  .swiper-button-right {
    top: 40%;
    right: -15px;
  }

  .arrow-icon {
    width: 10px;
    height: 10px;
  }
  .loading {
    width: 720px;
    padding-top: 17%;
  }

  ${({ theme }) =>
    theme.isMobile &&
    css`
      max-width: none;
      max-height: none;
      .loading {
        padding-top: 23.76%;
      }
    `}
`
