import { FC } from 'react'
import LazyLoad from 'react-lazyload'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { useGqlScreenHomeMobileQuery } from 'generated/graphql'
import BestStory from '@app.components/BestStory'
import HotLine from '@app.feature/home/components/HotLine/HotLine'
import LiveActivityInfoMobile from '@app.feature/home/components/LiveActivityInfo/mobile/LiveActivityInfo.mobile'
import NewBannerMobile from '@app.feature/home/components/NewBanner/mobile/NewBanner.mobile'
import PopularActivityMobile from '@app.feature/home/components/PopularActivity/mobile/PopularActivity.mobile'
import PopularChatRoomsMobile from '@app.feature/home/components/PopularChat/mobile/PopularChatRooms.mobile'
import PopularRecruitMobile from '@app.feature/home/components/PopularRecruit/mobile/PopularRecruit.mobile'
import RecommendActivityMobile from '@app.feature/home/components/RecommendActivity/mobile/RecommendActivity.mobile'
import { BestStoryType } from 'app.constants/enums'
import ActivityLoadingMobile from '../components/ActivityLoading/ActivityLoading.mobile'

gql`
  query gqlScreenHomeMobile {
    currentUser {
      ...PopularActivityMobile_currentUser
    }
  }
`

const ScreenHomeMobile: FC = () => {
  const { data, loading } = useGqlScreenHomeMobileQuery()

  return (
    <StyledWrapper>
      <RecommendActivityMobile
        currentUser={data?.currentUser || undefined}
        loading={loading}
      />
      <NewBannerMobile />
      <BestStory
        className="best-story"
        type={BestStoryType.ALL}
        isExpandBtn={false}
      />
      <HotLine className="hot-line" />
      <LazyLoad
        height={'100%'}
        placeholder={<ActivityLoadingMobile title="금주의 인기공고" />}
      >
        <PopularActivityMobile
          currentUser={data?.currentUser || undefined}
          loading={loading}
        />
      </LazyLoad>
      <LazyLoad
        height={'100%'}
        placeholder={<ActivityLoadingMobile title="실시간 인기 채용" />}
      >
        <PopularRecruitMobile
          currentUser={data?.currentUser || undefined}
          loading={loading}
        />
      </LazyLoad>
      <LazyLoad height={'100%'}>
        <PopularChatRoomsMobile />
      </LazyLoad>
      <LazyLoad
        height={'100%'}
        placeholder={
          <ActivityLoadingMobile title="실시간 활동정보" isCard={false} />
        }
      >
        <LiveActivityInfoMobile currentUser={data?.currentUser || undefined} />
      </LazyLoad>
    </StyledWrapper>
  )
}

export default ScreenHomeMobile

const StyledWrapper = styled.div`
  .best-story {
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
  }
  .hot-line {
    padding: 15px 0;
  }
`
