import { FC, useState } from 'react'
import gql from 'graphql-tag'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { MyActivities_CurrentUserFragment } from 'generated/graphql'
import Button from '@app.components/Button'
import ChannelDeactivatedModal from '@app.components/modals/ChannelDeactivatedModal'
import ChannelUnavailableModal from '@app.components/modals/ChannelUnavailableModal'
import { MEDIA_INTRODUCTION } from 'constants/urls'
import { LINKAREER_API_BASE_URL, LINKAREER_BASE_URL } from 'utils/config'

gql`
  fragment MyActivitiesManager_currentUser on User {
    id
    username
    notificationUnreadCount(notificationType: $notificationType)
    activitiesCountByUser
    channel {
      id
      url
      deactivatedAt
    }
  }
`

interface IFMyActivitiesManagerProps {
  currentUser: MyActivities_CurrentUserFragment
}

const MyActivitiesManager: FC<IFMyActivitiesManagerProps> = ({
  currentUser,
}) => {
  const router = useRouter()

  const [channelUnavailableModalopen, setChannelUnavailableModalOpen] =
    useState(false)
  const [channelDeactivatedModalopen, setChannelDeactivatedModalOpen] =
    useState(false)

  const handleClickChannelLink = () => {
    if (!currentUser.channel) {
      return setChannelUnavailableModalOpen(true)
    }

    router.push(`/channel/${currentUser.channel?.url}`)
  }

  const actionList = [
    {
      label: '진행중공고',
      href: '/manage/activity/',
      count: currentUser?.activitiesCountByUser || '0',
    },
    {
      label: '알림',
      href: '/notifications',
      count: currentUser?.notificationUnreadCount || '0',
    },
    {
      label: '매체소개서',
      href: MEDIA_INTRODUCTION,
    },
  ]

  return (
    <StyledWrapper>
      <div className="manager-info">
        <div>
          <div className="user-name">{currentUser.username}</div>
          <a className="my-channel" onClick={handleClickChannelLink}>
            내 채널 바로가기
          </a>
        </div>
        <Link
          href={`${LINKAREER_API_BASE_URL}/logout?next=${LINKAREER_BASE_URL}`}
        >
          <Button
            className="logout-button"
            variant="contained"
            color="lightGrey"
          >
            로그아웃
          </Button>
        </Link>
      </div>
      <div className="action-box">
        {actionList.map((action) => (
          <Link key={action.label} href={action.href}>
            <a className="action-item">
              {action.label}
              {!!action.count && <span className="count">{action.count}</span>}
            </a>
          </Link>
        ))}
      </div>
      <ChannelUnavailableModal
        open={channelUnavailableModalopen}
        onClose={() => setChannelUnavailableModalOpen(false)}
      />
      <ChannelDeactivatedModal
        open={channelDeactivatedModalopen}
        onClose={() => setChannelDeactivatedModalOpen(false)}
      />
    </StyledWrapper>
  )
}

export default MyActivitiesManager

const StyledWrapper = styled.div`
  .manager-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18.5px 20px;
    .user-name {
      max-width: 220px;
      font-size: 16px;
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .my-channel {
      font-size: 12px;
      font-weight: 500;
      color: #01a0ff;
    }
    .logout-button {
      font-size: 10px;
      color: #999;
      padding: 5px 8px;
      border-radius: 3px;
    }
  }
  .action-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    background-color: #f9f9f9;
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 5px 5px;
    .action-item {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 3px;
      font-size: 12px;
      border-right: 1px solid #e8e8e8;
      :last-child {
        border-right: none;
      }
      .count {
        color: #ef2929;
        font-weight: bold;
      }
    }
  }
`
